 jQuery( document ).ready(function() {
var btn = jQuery('#button');

jQuery(window).scroll(function() {
  if (jQuery(window).scrollTop() > 300) {
    btn.addClass('show');
  } else {
    btn.removeClass('show');
  }
});

// btn.on('click', function(e) {
//   e.preventDefault();
//   $('html, body').animate({scrollTop:0}, '300');
// });
 jQuery('.carousel').carousel();
});
const navlink = document.querySelectorAll("#button");
  navlink.forEach(elem => elem.addEventListener("click", smoothscroll));
  function smoothscroll(event){
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href");
    window.scrollTo({
      top: document.querySelector(targetId).offsetTop,
      behavior: "smooth"
    })
}
