import 'leaflet'

if (document.getElementById("map-canvas")) {
    var lat = 51.47930;
    var lng = 7.21728;
    var title = 'Chiropractic Bischoff<br>Kortumstraße 54<br>44787 Bochum';
    var map = L.map('map-canvas').setView([lat, lng], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        'attribution':  'Kartendaten &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> Mitwirkende',
        'useCache': true
    }).addTo(map);
    // var icon = L.icon({
    //     iconUrl: 'icon-orange-stecknadel-32x51.png',
    //     iconSize: [32, 51],
    //     popupAnchor: [0, -20]
    // });
    var marker = L.marker([lat, lng]).addTo(map);
    marker.bindPopup('<h3>'+title+'</h3>').openPopup();
}
