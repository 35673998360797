import 'jquery.mmenu/dist/jquery.mmenu'

$(document).ready(function() {
    $("#mobileMenu").mmenu({
        extensions: ["position-right"],
        onClick : {
            close          : true,
            preventDefault : false,
        }
    });
});